import type { NextPage, GetServerSideProps } from 'next'
import { PageTemplate } from '../components/PageTemplate'
import { Layout } from '../components/Layout'
import { PageContent, PageConfig } from '../types/page_component_types'
import { getPagePropsBySlug } from '../utils/get_page_props_by_slug'
import { Locale } from '../types/shared_types'

const CurrentPage: NextPage<{
  pageContent?: PageContent
  pageConfig: PageConfig
}> = ({ pageContent, pageConfig }) => {
  return (
    <Layout
      config={pageConfig}
      meta={pageContent?.meta}
      slug={pageContent?.slug}
    >
      <PageTemplate {...pageContent} />
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async context => {
  return await getPagePropsBySlug(context, '')
}

export default CurrentPage
